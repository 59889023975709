
import IconBase from '~/components/icons/IconBase'

export default {
  extends: IconBase,
  props: {
    width: {
      type: [Number],
      default: 883.64,
    },
    height: {
      type: [Number],
      default: 1074.3,
    },
  },
}
